<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12" md="6">
        <event-form
          :evt="evt"
          v-if="evt"
          :statuses="statuses"
          :categories="categories"
          :updateEvent="updateEvent"
          :submit="createEventAsync"
        ></event-form>
        <v-snackbar
          v-model="snackbar.visible"
          :color="snackbar.color"
          top
          right
        >
          {{ snackbar.text }}

          <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="snackbar.visible = false">
              Fermer
            </v-btn>
          </template>
        </v-snackbar>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <calendar-component-details
          :start="start"
          v-if="start"
          ref="calendardetails"
        ></calendar-component-details>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import EventForm from "@/components/EventForm.vue";
import CalendarComponentDetails from "@/components/CalendarComponentDetails.vue";
export default {
  components: { EventForm, CalendarComponentDetails },
  props: ["statuses", "categories"],
  data: () => ({
    evt: {
      contact: undefined,
      author: undefined,
      _id: "create",
      startDate: undefined,
      endDate: undefined,
    },
    snackbar: {
      visible: false,
      text: "",
      color: "",
    },
    start: new Date(),
    loading: false,
  }),
  methods: {
    updateEvent(evt) {
      this.$refs.calendardetails.updateSelectedEvent(evt);
    },
    async createEventAsync(evt) {
      this.loading = true;
      try {
        delete evt._id;
        const { data } = await axios({
          method: "post",
          url: `${process.env.VUE_APP_API_URI}/admin/events`,
          data: evt,
        });
        this.evt = data;
        localStorage.setItem(
          "snackbar",
          JSON.stringify({
            visible: true,
            text: "Événement créé",
            color: "success",
          })
        );
        this.$router.push(`/events/${data._id}`);
      } catch (error) {
        console.log(error);
        this.snackbar = {
          visible: true,
          text: "Erreur de création",
          color: "error",
        };
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>