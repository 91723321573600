import Vue from "vue";
import VueRouter from "vue-router";
import CalendarView from "../views/CalendarView.vue";
import EventsList from "../views/EventsList.vue";
import EventsShow from "../views/EventsShow.vue";
import EventCreate from "../views/EventCreate.vue";
import CategoriesList from "../views/CategoriesList.vue";
import CategoriesCreate from "../views/CategoriesCreate.vue";
import CategoriesShow from "../views/CategoriesShow.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "calendar",
    component: CalendarView,
  },
  {
    path: "/events",
    name: "events",
    component: EventsList,
  },
  {
    path: "/events/create",
    name: "events create",
    component: EventCreate,
  },
  {
    path: "/events/:_id",
    name: "events show",
    component: EventsShow,
  },
  {
    path: "/categories",
    name: "categories",
    component: CategoriesList,
  },
  {
    path: "/categories/create",
    name: "categories create",
    component: CategoriesCreate,
  },
  {
    path: "/categories/:_id",
    name: "categories show",
    component: CategoriesShow,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
