<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <div>Formats d'événement</div>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :loading="loading"
            :items="categories"
            class="elevation-1"
            :footer-props="{
              itemsPerPageOptions: [10, 30, 50],
              itemsPerPageText: 'Élements par page',
            }"
          >
            <template v-slot:body="{ items }">
              <tbody class="text-left">
                <tr
                  v-for="item in items"
                  :key="item._id"
                  @click="$router.push(`/categories/${item._id}`)"
                >
                  <td>{{ item.name }}</td>
                  <td>{{ item.count }}</td>
                  <td>
                    {{ format(parseISO(item.createdAt), "dd.MM.yyyy HH:mm") }}
                  </td>
                  <td class="text-center">
                    <v-btn
                      v-if="
                        !item.deletedAt &&
                        deleteConfirmation !== item._id &&
                        item.count === 0
                      "
                      small
                      text
                      @click.stop="setDeleteConfirmation(item._id)"
                      >Supprimer</v-btn
                    >
                    <v-btn
                      v-if="!item.deletedAt && deleteConfirmation === item._id"
                      small
                      color="error"
                      text
                      @click.stop="deleteItem(item._id)"
                      >Confirmer</v-btn
                    >
                  </td>
                </tr>
              </tbody></template
            >
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" top right>
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.visible = false">
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import { format, parseISO } from "date-fns";

export default {
  props: ["deleteCategorie"],
  name: "CategoriesList",
  data: () => ({
    categories: [],
    loading: false,
    headers: [
      { text: "Nom", value: "name" },
      { text: "Nombre d'événements liés", value: "count" },
      { text: "Créée le", value: "createdAt" },
      { text: "Supression", align: "center" },
    ],
    snackbar: {
      visible: false,
      text: "",
      color: "",
    },
    format,
    parseISO,
    deleteConfirmation: "",
  }),
  mounted() {
    this.loadCategories();
  },
  methods: {
    async deleteItem() {
      try {
        const { data: deleted } = await axios({
          method: "delete",
          url: `${process.env.VUE_APP_API_URI}/admin/categories/${this.deleteConfirmation}`,
        });
        this.deleteCategorie(deleted);
        this.categories = this.categories.filter((c) => c._id !== deleted._id);
      } catch (error) {
        console.log(error);
        this.snackbar = {
          visible: true,
          color: "error",
          text: "Erreur de suppression",
        };
      } finally {
        this.deleteConfirmation = "";
      }
    },
    setDeleteConfirmation(_id) {
      this.deleteConfirmation = _id;
    },
    async loadCategories() {
      this.loading = true;
      try {
        const { data } = await axios({
          method: "get",
          url: `${process.env.VUE_APP_API_URI}/admin/categories`,
        });
        this.categories = data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
