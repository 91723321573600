<template>
  <v-card class="mb-5">
    <v-card-text class="pa-10">
      <v-form v-model="valid">
        <v-text-field
          v-model="editedCategory._id"
          label="ID"
          outlined
          dense
          disabled
          v-show="editedCategory._id !== 'create'"
        ></v-text-field>
        <v-text-field
          v-model="editedCategory.name"
          label="Titre *"
          outlined
          dense
          :rules="[rules.required]"
        ></v-text-field>
        <v-btn
          outlined
          block
          class="mb-3"
          @click="
            submit({
              ...editedCategory,
            })
          "
          :disabled="!valid"
          >Soumettre</v-btn
        >
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["category", "submit"],
  data: () => ({
    valid: false,
    editedCategory: {},
    rules: {
      required: (value) => !!value || "Ce champ est requis",
    },
  }),
  mounted() {
    this.editedCategory = this.category;
  },
};
</script>

