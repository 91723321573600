var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',[_c('div',[_vm._v("Formats d'événement")]),_c('v-spacer')],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"loading":_vm.loading,"items":_vm.categories,"footer-props":{
            itemsPerPageOptions: [10, 30, 50],
            itemsPerPageText: 'Élements par page',
          }},scopedSlots:_vm._u([{key:"body",fn:function({ items }){return [_c('tbody',{staticClass:"text-left"},_vm._l((items),function(item){return _c('tr',{key:item._id,on:{"click":function($event){return _vm.$router.push(`/categories/${item._id}`)}}},[_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(item.count))]),_c('td',[_vm._v(" "+_vm._s(_vm.format(_vm.parseISO(item.createdAt), "dd.MM.yyyy HH:mm"))+" ")]),_c('td',{staticClass:"text-center"},[(
                      !item.deletedAt &&
                      _vm.deleteConfirmation !== item._id &&
                      item.count === 0
                    )?_c('v-btn',{attrs:{"small":"","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.setDeleteConfirmation(item._id)}}},[_vm._v("Supprimer")]):_vm._e(),(!item.deletedAt && _vm.deleteConfirmation === item._id)?_c('v-btn',{attrs:{"small":"","color":"error","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(item._id)}}},[_vm._v("Confirmer")]):_vm._e()],1)])}),0)]}}])})],1)],1)],1),_c('v-snackbar',{attrs:{"color":_vm.snackbar.color,"top":"","right":""},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar.visible = false}}},'v-btn',attrs,false),[_vm._v(" Fermer ")])]}}]),model:{value:(_vm.snackbar.visible),callback:function ($$v) {_vm.$set(_vm.snackbar, "visible", $$v)},expression:"snackbar.visible"}},[_vm._v(" "+_vm._s(_vm.snackbar.text)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }