<template>
  <v-container>
    <v-card>
      <v-card-text class="pa-10"> <calendar-component></calendar-component></v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import CalendarComponent from "@/components/CalendarComponent.vue";

export default {
  name: "CalendarView",
  components: { CalendarComponent },
};
</script>